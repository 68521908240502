<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-success-model
        message="{{ sMessage }}"
        [display]="displaySuccess"
        (event)="closeModel($event)"></app-success-model>
    <app-enterprise-navigation></app-enterprise-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <section class="content-header">
            <div class="row">
                <div class="col-md-6">
                    <div class="tab-heading">
                        <span class="tab-heading-title">Transfer to Bank</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="add-section width-50" style="margin-top: 8px">
            <div class="row">
                <div class="col-md-12 section-heading">Wallet Balance</div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>FULL:</label>
                    <div>{{ fullBalance || '0.00' | number: amountFormat }}</div>
                </div>
                <div class="col-md-6">
                    <label>AVAILABLE:</label>
                    <div>{{ availableBalance || '0.00' | number: amountFormat }}</div>
                </div>
                <!-- <div class="col-md-6 mt-5">
                    <div class="ttb-instruction">
                        <div class="row pl-4">
                            <div class="col-6">
                                <strong>Transfers before 12:00 PM*</strong>
                                <br /><span>business days will reflect the next morning</span>
                            </div>
                            <div class="col-6">
                                <strong>Transfers before 12:00 PM*</strong>
                                <br /><span>business days will reflect a full day later</span>
                            </div>
                        </div>
                        <hr />
                        <div class="vr"></div>
                        <div class="row pl-4">
                            <div class="col-6">
                                <strong>Transfers on a Saturday*</strong>
                                <br /><span>will reflect a full day later</span>
                            </div>
                            <div class="col-6">
                                <strong>Transfers on a Sunday*</strong>
                                <br /><span>will reflect a full day later</span>
                            </div>
                        </div>
                        <div class="mt-3 text-center">*Excludes Public Holidays</div>
                    </div>
                </div> -->
            </div>
        </section>
        <section class="add-section">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-md-12 section-heading">Transfer to Bank</div>
                </div>
                <div class="row pb-3">
                    <div class="col-md-6">
                        <div class="use-full-balance-link" *ngIf="showFullBalance">
                            <a (click)="setBalance()"><u>Use Full Balance</u></a>
                        </div>
                        <div class="input-icons">
                            <input
                                class="form-input"
                                type="text"
                                appFloatOnly
                                [maxVal]="availableBalance + 0.01"
                                formControlName="text" />
                            <a *ngIf="form.get('text').value.length > 0" (click)="clearText()">
                                <img src="assets/images/close-x-purple.png" />
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <button
                                class="search-button orange"
                                (click)="submit()"
                                [disabled]="disabled">
                                Request Transfer to Bank
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </section>
        <div class="progress-container" *ngIf="isLoading">
            <div class="progress-container-text">
                <div>Searching...</div>
            </div>
            <div>
                <div class="custom-progress-bar">
                    <span [style.width.%]="progressWidth"></span>
                </div>
            </div>
        </div>
        <section *ngIf="search">
            <div class="search-result-header">Transfer History</div>
            <hr />
            <div
                class="container-fluid tab-pane custom-table custom-last-row"
                *ngIf="transactionList">
                <br />
                <table id="tableData">
                    <thead>
                        <tr>
                            <th>
                                <app-filter-arrow
                                    filter="name"
                                    title="DATE & TIME REQUESTED"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <!-- <th>
                                <app-filter-arrow
                                    filter="mobileNumber"
                                    title="PROCESSING DATE"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th> -->
                            <th>
                                <app-filter-arrow
                                    filter="role"
                                    title=" STATUS"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow title="AMOUNT"> </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow title="ACTIONS"> </app-filter-arrow>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tablebox" *ngFor="let tran of transactionList">
                            <td>{{ tran.receivedAt | date: 'dd MMM yyyy HH:mm' }}</td>
                            <!-- <td>{{ tran.settleOn | date: dateFormat }}</td> -->
                            <td>
                                <app-account-status
                                    type="transaction"
                                    transactionStatus="{{ tran.status }}">
                                </app-account-status>
                            </td>
                            <td>{{ tran.amount }}</td>
                            <td>
                                <app-last-child-enterprise-ttb
                                    (event)="action($event)"
                                    [transaction]="tran">
                                </app-last-child-enterprise-ttb>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot *ngIf="pagination.showPagination()">
                        <tr>
                            <td>
                                Rows per page:
                                <span
                                    ><b>{{ pagination.length }}</b></span
                                >
                                <app-page-length-dropdown (emitLength)="changeLength($event)">
                                </app-page-length-dropdown>
                            </td>
                            <td>
                                <app-page-navigation
                                    pageNumber="{{ pagination.getPageNo() }}"
                                    totalPages="{{ pagination.getTotalPages() }}"
                                    (changePage)="changePage($event)">
                                </app-page-navigation>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </section>
        <div
            id="settlementViewModal"
            class="transaction-details-window"
            [style.display]="displayView">
            <div class="transaction-details-header">
                <div class="section-heading">
                    Bank Transfer Details
                    <a (click)="closeViewModel()"
                        ><img class="float-right" src="assets/images/close-x-purple.png"
                    /></a>
                </div>
                <hr />
                <div class="transaction-details-header-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">USER OR BUSINESS NAME</label>
                            <div class="user-details-text">
                                <strong>{{ transaction.fromUserName }}</strong>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label"></label>
                            <app-account-status
                                type="transaction"
                                transactionStatus="{{ transaction.status }}">
                            </app-account-status>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">USER TYPE</label>
                            <div class="user-details-text">
                                {{ transaction.fromRole | humanize }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">RECEIVED</label>
                            <div class="user-details-text">
                                {{ transaction.receivedAt | date: dateFormat }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">Request Fee</label>
                            <div class="user-details-text">
                                {{ transaction.senderFee || '0.00' | number: amountFormat }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">DUE</label>
                            <div class="user-details-text">
                                {{ transaction.settleOn | date: dateFormat }}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="section-heading">Bank Details</div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">BANK</label>
                            <div class="user-details-text">
                                {{ transaction.bankDetails.bankName }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">BRANCH</label>
                            <div class="user-details-text">
                                {{ transaction.bankDetails.bankLocation }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">ACCOUNT NUMBER</label>
                            <div class="user-details-text">
                                {{ transaction.bankDetails.accountNumber }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">BRANCH NUMBER</label>
                            <div class="user-details-text">
                                {{ transaction.bankDetails.branchCode }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">ACCOUNT HOLDER</label>
                            <div class="user-details-text">
                                {{ transaction.bankDetails.accountHolderName }}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="section-heading">Logs</div>
                    <table>
                        <tbody>
                            <tr *ngFor="let tran of transaction.transactionLog">
                                <td class="pb-2">
                                    <div class="transaction-details-log-date">
                                        <label class="form-label mb-0">
                                            {{ tran.createdAt | date: 'd/M/yyyy - h:mm a' }}
                                        </label>
                                    </div>
                                    <div
                                        class="circle-left circle-green"
                                        *ngIf="tran.status === transactionStatusEnum.success"></div>
                                    <div
                                        class="circle-left circle-green"
                                        *ngIf="tran.status === transactionStatusEnum.settled"></div>
                                    <div
                                        class="circle-left circle-red"
                                        *ngIf="tran.status === transactionStatusEnum.failed"></div>
                                    <div
                                        class="circle-left circle-red"
                                        *ngIf="
                                            tran.status === transactionStatusEnum.cancelled
                                        "></div>
                                    <div
                                        class="circle-left circle-yello"
                                        *ngIf="tran.status === transactionStatusEnum.pending"></div>
                                    <div
                                        class="circle-left circle-orange"
                                        *ngIf="
                                            tran.status === transactionStatusEnum.processing
                                        "></div>
                                    {{ tran.message }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="transaction-details-footer">
                Amount:
                <span class="float-right pr-3"
                    >R{{ transaction.amount || '0.00' | number: amountFormat }}</span
                >
            </div>
        </div>
        <div id="cancelSettlementModal" class="image-modal" [style.display]="displayCancel">
            <div class="logout-window">
                <div class="section-heading mb-1">Cancel your Transfer Request?</div>
                <div>
                    <button
                        type="submit"
                        class="box-outline submit-btn mb-2"
                        (click)="cancelRequest()">
                        Cancel Request
                    </button>
                    <a class="deny-window-close" (click)="closeCancelModel()"><u>BACK</u></a>
                </div>
            </div>
        </div>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
