<div class="wrapper">
    <app-navigation></app-navigation>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-header
            buttonText="Add New User"
            title="Manage Users"
            location="OTPs"
            [showHeaderButton]="true">
        </app-header>
        <app-links></app-links>

        <div>
            <section class="add-section">
                <div class="row">
                    <div class="col-md-12 section-heading">Search</div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <div class="input-icons">
                            <i class="fa fa-search icon" aria-hidden="true"></i>
                            <input
                                class="form-input"
                                type="text"
                                placeholder="Enter mobile number"
                                (input)="onInputChange($event)"
                                [value]="searchText" />
                            <a *ngIf="searchText" (click)="clearText()">
                                <img src="assets/images/close-x-purple.png" />
                            </a>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <button class="search-button" (click)="submit()">Search</button>
                    </div>
                </div>
            </section>
            @if (data?.OTP) {
                <section>
                    <div class="container-fluid tab-pane">
                        <br />
                        <div class="row">
                            <div class="col-md-10 section-heading">OTP: {{ data.OTP }}</div>
                            <div class="col-md-10 section-heading">STATUS: {{ data.status }}</div>
                        </div>
                    </div>
                </section>
            }
        </div>
    </div>
</div>
