<section class="content">
    <!-- Default box -->
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                routerLink="{{ uri.SLASHED_SEARCH }}">
                Search Users</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                routerLink="{{ uri.SLASHED_VERIFY_USERS }}">
                Verify Users</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('manageUsersGetOtp')">
                OTPs
            </a>
        </li>
    </ul>
</section>
