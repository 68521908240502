import { environment } from '../../environments/environment';

export class AppSettings {
    public static API_ENDPOINT = environment.apiUrl;
    public static SIGNUP = 'user-signup';
    public static LOGIN = 'auth/login';
    public static VALIDATE_CREDENTIALS = 'admin/credentials/verify';
    public static VERIFY_PIN = 'pin/verify';
    public static FILTERED_USERS = 'users/filter';

    // Dropdown Filters
    public static DROPDOWN_GENDER = 'dropdown/gender';
    public static DROPDOWN_KYCLEVEL = 'dropdown/kycLevel';
    public static DROPDOWN_ID_TYPE = 'dropdown/idType';
    public static DROPDOWN_AGENT_TYPE = 'dropdown/agentType';
    public static DROPDOWN_ADMIN_TYPE = 'dropdown/adminType';
    public static DROPDOWN_USER_STATUS = 'dropdown/userStatus';
    public static DROPDOWN_ACCOUNT_TYPE = 'dropdown/accountType';
    public static DROPDOWN_BANK_ACCOUNT_TYPE = 'dropdown/bankAccountType';
    public static DROPDOWN_MERCHANT_TYPE = 'dropdown/merchantType';
    public static DROPDOWN_MARITAL_STATUS = 'dropdown/maritalStatus';
    public static DROPDOWN_PHONE_TYPE = 'dropdown/mobilePhoneType';
    public static DROPDOWN_STORE_TYPE = 'dropdown/storeType';
    public static DROPDOWN_PROVINCE = 'country/@@@/provinces';
    public static DROPDOWN_CITY = 'provinces/@1@/cities';
    public static DROPDOWN_BUSINESS_CATEGORY = 'dropdown/businessCategory';
    public static DROPDOWN_BANK = 'banks';
    public static DROPDOWN_SALES_DASHBOARD = 'dropdown/salesDashboard/list';

    public static GET_USER_PROFILE = 'admin/users/@@@/profile';
    public static UPDATE_USER_PROFILE = 'users/@1@/@2@/profile';
    public static VERIFY_USER_DOCUMENT = 'admin/users/@@@/verify';
    public static UPDATE_LOGO = 'document/users/@1@/@2@/image/upload/noauth';
    public static CHANGE_PROFILE_SETTINGS = 'admin/users/@1@/@2@/profile/settings';
    public static CHANGE_PROFILE = 'admin/users/@1@/@2@/profile';
    public static UPDATE_STATUS = 'update-status';
    public static VALIDATE_EMAIL = 'validate-email';
    public static VALIDATE_MOBILE = 'validate-mobile';
    public static CHANGE_MOBILE = 'admin/auth/otp/challenge';
    public static VERIFY_OTP = 'otp/validate';
    public static RESEND_OTP = 'otp/resend';
    public static FORGOT_PASSWORD = 'admin/forgot-password/';
    public static CHANGE_PASSWORD = 'admin/change-password';
    public static TERMS_AND_CONDTITIONS = 'admin/terms-and-conditions';
    public static ADD_AGENT = 'admin/approve/agent/@@@';
    public static USER_TRANSACTION = 'admin/transactions';
    public static UPDATE_TRANSACTION_STATUS = 'users/transaction/status';
    public static UPDATE_TRANSACTION_AMOUNT = 'users/transaction/amount';
    public static USER_TRANSACTION_EXPORT_LOG = 'admin/transactions/settlement-requests/export-log';
    public static GET_TRANSACTION_EXPORT_LOG =
        'admin/transactions/settlement-requests/view/export-log';
    public static UPDATE_BANK = 'users/@1@/role/@2@/banks';
    public static UPDATE_ADDRESS = 'admin/user/@1@/address';
    public static CHECK_BALANCE = 'users/check-balance';
    public static UPDATE_REFERRAL_CODE = 'admin/update/@1@/referred-by/@2@';
    public static SEND_EFT_COMMS = 'admin/users/transaction/eft/notification';

    // transaction api settings
    public static TRANSACTION_API_ENDPOINT = environment.transactionUrl;
    public static DROPDOWN_TRANSACTION_TYPE = 'dropdown/transactionType';
    public static DROPDOWN_TRANSACTION_STATUS = 'dropdown/transactionStatus';
    public static GET_FEES = 'fees';
    public static GET_FEE_BY_CODE = 'fees/@1@';
    public static GET_CUSTOM_FEE = 'fees/custom/@1@/role/@2@';
    public static UPDATE_FEES = 'fees';
    public static UPDATE_CUSTOM_FEES = 'fees/custom/update';
    public static GET_KYC_LIMIT = 'kyc-level/limits';
    public static UPDATE_KYC_LIMIT = 'kyc-level/limits';
    public static GET_DAILY_LIMIT = 'daily-limits';
    public static UPDATE_DAILY_LIMIT = 'daily-limits';
    public static UPDATE_TRANSFER_TO_BANK = 'transaction/settlement-request/@1@';

    // Enterprise
    public static ADD_ENTERPRISE = 'admin/enterprise';
    public static CREATE_PASSWORD = 'admin/create-password/link';
    public static RESEND_CREATE_PASSWORD_LINK = 'admin/create-password/link/@1@/resend';
    public static GET_ENTERPRISE_PROFILE = 'admin/enterprise/@1@/profile';
    public static GET_ENTERPRISE_EMPLOYEES = 'admin/enterprise/@1@/employees';
    public static ADD_ENTERPRISE_STAFF = 'admin/enterprise/staff';
    public static UPDATE_ENTERPRISE_STAFF = 'admin/enterprise/update-staff';
    public static UPDATE_ENTERPRISE_STAFF_PIN = 'pin/update';
    public static DEACTIVATE_ENTERPRISE_STAFF = 'admin/users/enterprise/staff/@1@';
    public static ENTERPRISE_TRANSACTION = 'admin/enterprise/staff/transactions';
    public static UPDATE_ENTERPRISE_CONTACT = 'admin/enterprise/@1@/contact';
    public static ADD_ENTERPRISE_CASHBACK_SETTINGS = 'admin/users/cashback/settings';
    public static GET_ENTERPRISE_CASHBACK_SETTINGS = 'admin/users/@1@/cashback/settings';
    public static UPDATE_ENTERPRISE_CASHBACK_SETTINGS = 'admin/users/cashback/settings';
    public static DELETE_ENTERPRISE_CASHBACK_SETTINGS = 'cashback/settings/@1@/deactivate';
    public static CREATE_TRANSFER_TO_BANK_REQUEST = 'settlement-request';
    public static CANCEL_TRANSFER_TO_BANK_REQUEST = 'settlement-request/@1@';
    public static TRANSFER_TO_BANK_WITHOUT_NETCASH = 'users/transaction/transfer-to-bank/process';
    public static CANCEL_BANK_TRANSFER = 'users/transaction/eft';
    public static REFUND_TRANSACTION = 'users/transactions/@1@/refund';
    public static SETTLEMENT_BATCH_RUN = 'users/transaction/settlement/run-batch';
    public static ENTERPRISE_PRODUCT_ORDERS = 'enterprise/product-orders';
    public static FULFILL_ENTERPRISE_PRODUCT_ORDER = 'enterprise/product-orders/@1@';

    //Smart Safe
    public static GET_SMART_SAFE_UNASSIGNED_USERS = 'admin/smartsafe/assignedToList';
    public static GET_SMART_SAFE_MODELS = 'smartsafe/models';
    public static GET_SMART_SAFE_STATUSES = 'smartsafe/statuses';
    public static GET_SMART_SAFE = 'admin/smartsafes';
    public static SAVE_SMART_SAFE = 'admin/smartsafe/';
    public static UPDATE_SMART_SAFE = 'admin/smartsafe/';
    public static SMART_SAFES_SUMMARY = 'admin/smartsafes/summary';
    public static GET_SMART_SAFES = 'admin/smartsafes';
    public static GET_SMART_SAFE_TRANSACTION = 'admin/smartsafes/transactions';
    public static GET_CIT_COMPANIES = 'admin/cit-companies';
    public static CRETAE_CIT = 'admin/cit-companies';
    public static ARCHIVE_SMART_SAFES = 'admin/smartsafes/bulk-archive';

    // dashboard
    public static GET_DASHBOARD_BALANCE = 'admin/dashboard/balance';
    public static GET_USER_WALLET_SUMMARY = 'users/@1@/transactions/grand-total';
    public static GET_USER_RECONCILIATION_DATA = 'admin/dashboard/users/reconcilation';
    public static GET_SALES_DATA = 'dashboard/sales';

    // Banners
    public static BANNERS = 'banners';
    public static UPDATE_BANNER = 'banners/@@@';

    // GRV
    public static GET_GRV_BY_PURCHASE_ORDER_ID = 'admin/shopify/stocky/grv/@1@';
    public static GET_GRV_LIST = 'admin/shopify/stocky/grvs';
    public static CREATE_GRV = 'admin/shopify/stocky/grv';

    // companies
    public static GET_COMPANIES = 'companies';

    // Cashback Reports
    public static GET_CASHBACK_REPORTS = 'cashback-report';
    public static GET_WHOLESALER_CASHBACK_REDEEMED = 'enterprise/@1@/wholesaler/cashbacks-redeemed';

    // Get OTP
    public static GET_REGISTRATION_OTP = 'otp/user-registration';
}
