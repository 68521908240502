import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { Pagination } from 'src/app/model/Pagination';
import { CompanyService } from 'src/app/services/company.service';
import { DashboardService } from '../../services/dashboard.service';
import { AppConstants } from '../../settings/app-constants';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
    display = false;
    amountFormat = AppConstants.AMOUNT_FORMAT;
    ethBalance: string;
    czarBalance: string;
    address: string;
    isLoading = false;
    summary: any;
    spinnerWavesBalance = false;
    spinnerWalletSummary = false;
    spinnerReconciliationData = false;
    form: FormGroup;
    pagination = new Pagination();
    reconciliationData: any[];

    constructor(
        private dashboardService: DashboardService,
        private formBuilder: UntypedFormBuilder,
        public companyService: CompanyService,
    ) {
        this.form = this.formBuilder.group({
            text: [''],
            start: [''],
            length: [''],
        });
    }

    ngOnInit() {
        this.getWavesBalance();
        this.getWalletSummary();
        this.getReconciliationData();
    }

    getWavesBalance() {
        this.spinnerWalletSummary = true;

        this.dashboardService.getDashboardBalance().subscribe(
            (res) => {
                this.czarBalance = res.data.czarBalance || '';
                this.ethBalance = res.data.ethBalance || '';
                this.address = res.data.address || '';
                this.spinnerWalletSummary = false;
            },
            (err) => {
                this.spinnerWalletSummary = false;
            },
        );
    }

    getWalletSummary() {
        this.spinnerWavesBalance = true;

        this.dashboardService.getWalletSummary('1').subscribe(
            (res) => {
                this.summary = res;
                this.spinnerWavesBalance = false;

                if (!this.summary.reconciliationPassed) {
                    this.display = true;
                }
            },
            (err) => {
                this.spinnerWavesBalance = false;
            },
        );
    }

    getReconciliationData() {
        this.spinnerReconciliationData = true;
        this.form.get('start').setValue(this.pagination.start / 10);
        this.form.get('length').setValue(this.pagination.length);

        this.dashboardService.getReconciliationData(this.form.value).subscribe(
            (res) => {
                this.reconciliationData = res.data;
                this.pagination.total = res.total;
                this.spinnerReconciliationData = false;
            },
            (error) => {
                this.spinnerReconciliationData = false;
            },
        );
    }

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getReconciliationData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getReconciliationData();
    }
}
