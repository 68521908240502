import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'app-otps',
    templateUrl: './otps.component.html',
    styleUrl: './otps.component.css'
})
export class OtpsComponent {

    searchText: string = '';
    data: any;
    isLoading: boolean = false;

    constructor(
        private commonService: CommonService,
        private toasterService: ToastrService,
    ) { }

    onInputChange(event: Event) {
        const target = event.target as HTMLInputElement;
        this.searchText = target.value;
    }

    clearText() {
        this.searchText = '';
    }

    submit() {
        this.data = null;
        const params = { "mobile_number": this.searchText };
        this.commonService.getOTPs(params).subscribe({
            next: (res) => {
                if (res.success) {
                    this.data = res.responseBody.data;
                } else {
                    this.toasterService.error(res.status.message, 'Error', { tapToDismiss: true });
                }
            },
            error: () => {
                this.isLoading = false;
            }
        });
    }
}
