<div class="content-wrapper">
    <app-navigation></app-navigation>
    <app-content-transition></app-content-transition>
    <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
    <app-header title="Dashboard"></app-header>
    <section class="content mb-4">
        <div class="row mb-4">
            <div class="col-md-12 section-heading">
                {{ companyService.getSelectedCompanyName() }} Sales
                <hr class="hr-margin-none" />
            </div>
        </div>
        <div class="d-flex align-items-baseline">
            <div class="sales-dashboard" *ngIf="!spinnerWavesBalance">
                <app-sales-dashboard></app-sales-dashboard>
            </div>

            <!-- <div class="waves-system-balance-box width-50" *ngIf="spinnerWavesBalance">
                    <mat-progress-spinner
                        class="example-margin"
                        color="warn"
                        mode="indeterminate"
                        diameter="30"
                    >
                    </mat-progress-spinner>
                </div> -->
        </div>
    </section>
    <section class="content">
        <div class="row mb-4">
            <div class="col-md-12 section-heading">
                {{ companyService.getSelectedCompanyName() }} System Account Information
                <hr class="hr-margin-none" />
            </div>
        </div>
        <div class="d-flex align-items-baseline">
            <div class="waves-system-balance-box width-50" *ngIf="!spinnerWavesBalance">
                <div class="section-heading">
                    {{ companyService.getSelectedCompanyName() | uppercase }} PARENT Wallet
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">
                            CZAR WALLET TOTAL
                            <a class="open-link-icon white" target="_blank" href="{{ address }}">
                                <img src="assets/images/open-icon.png" />
                                OPEN
                            </a>
                        </label>
                        <div class="dashboard-amount grey">
                            {{ czarBalance || '0.00' | number: amountFormat | replace: ' ' }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">
                            ETHEREUM WALLET TOTAL
                            <a class="open-link-icon white" target="_blank" href="{{ address }}">
                                <img src="assets/images/open-icon.png" />
                                OPEN
                            </a>
                        </label>
                        <div class="dashboard-amount grey">
                            {{ ethBalance || '0.00' | number: amountFormat | replace: ' ' }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="waves-system-balance-box width-50" *ngIf="spinnerWavesBalance">
                <mat-progress-spinner
                    class="example-margin"
                    color="warn"
                    mode="indeterminate"
                    diameter="30">
                </mat-progress-spinner>
            </div>
        </div>
    </section>
    <section class="content mt-4">
        <div class="row mb-4">
            <div class="col-md-12 section-heading">
                Platform totals over time
                <hr class="hr-margin-none" />
            </div>
        </div>
        <div
            class="alert alert-danger alert-dismissible fade show mt-4"
            *ngIf="display && !spinnerWalletSummary">
            Balances are not reconciling.
            <button
                type="button"
                class="btn-close"
                data-dismiss="alert"
                aria-label="Close"></button>
        </div>
        <div class="d-flex align-items-baseline" *ngIf="!spinnerWalletSummary">
            <div class="waves-system-balance-box width-33">
                <div class="section-heading">Money In</div>
                <div class="row">
                    <div class="col-md-12">
                        <label class="form-label">FEES RECEIVED TOTAL</label>
                        <div class="dashboard-amount green">
                            {{ summary?.totalFees || '0.00' | number: amountFormat | replace: ' ' }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label class="form-label">CASH IN TOTAL</label>
                        <div class="dashboard-amount green">
                            {{
                                summary?.totalCashin || summary?.totalSent || '0.00'
                                    | number: amountFormat
                                    | replace: ' '
                            }}
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-12">
                        <label class="form-label">SUBTOTAL</label>
                        <div class="dashboard-amount green">
                            {{
                                summary?.totalMoneyIn || '0.00'
                                    | number: amountFormat
                                    | replace: ' '
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="waves-system-balance-box width-33">
                <div class="section-heading">Money Out</div>
                <div class="row">
                    <div class="col-md-12">
                        <label class="form-label">TOTAL TRANSFER TO BANK</label>
                        <div class="dashboard-amount light-red">
                            {{
                                summary?.totalSettlement || '0.00'
                                    | number: amountFormat
                                    | replace: ' '
                            }}
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-12">
                        <label class="form-label">SUBTOTAL</label>
                        <div class="dashboard-amount light-red">
                            {{
                                summary?.totalMoneyOut || '0.00'
                                    | number: amountFormat
                                    | replace: ' '
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column width-33">
                <div class="waves-system-balance-box">
                    <div class="section-heading">Balance</div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">ALL WALLET BALANCE TOTALS</label>
                            <div class="dashboard-amount purple">
                                {{
                                    summary?.totalWalletBalance || '0.00'
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="waves-system-balance-box">
                    <div class="section-heading">Reconciliation Difference</div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">TOTAL RECONCILIATION DIFFERENCE</label>
                            <div class="dashboard-amount purple">
                                {{
                                    summary?.reconciliationDifference || '0.00'
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="waves-system-balance-box width-50" *ngIf="spinnerWalletSummary">
            <mat-progress-spinner
                class="example-margin"
                color="warn"
                mode="indeterminate"
                diameter="30">
            </mat-progress-spinner>
        </div>
    </section>
    <section>
        <div class="row mt-4">
            <div class="col-md-12 section-heading">
                Reconciliation by user
                <hr class="hr-margin-none" />
            </div>
        </div>
        <div class="container-fluid tab-pane custom-table" *ngIf="!spinnerReconciliationData">
            <br />
            <table id="tableData">
                <thead>
                    <tr>
                        <th width="40%">
                            <app-filter-arrow filter="name" title="NAME & SURNAME">
                            </app-filter-arrow>
                        </th>
                        <th width="30%">
                            <app-filter-arrow title="Reconciliation Passed"> </app-filter-arrow>
                        </th>
                        <th width="30%">
                            <app-filter-arrow title="Reconciliation Difference"> </app-filter-arrow>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tablebox" *ngFor="let user of reconciliationData">
                        <td width="40%">
                            <img
                                class="table-user-img"
                                src="{{
                                    user.profilePicture || 'assets/images/placeholder.png'
                                }}" />
                            <!-- <a (click)="commonUtil.navigate('profile', user)">
                                    <strong><u></u></strong>
                                </a> -->
                            <span>{{ user.name }}</span>
                        </td>
                        <td width="30%">{{ user.reconciliationPassed ? 'TRUE' : 'FALSE' }}</td>
                        <td width="30%">
                            {{ user.reconciliationDifference || '0.00' | number: amountFormat }}
                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf="pagination.showPagination()">
                    <tr>
                        <td>
                            Rows per page:
                            <span
                                ><b>{{ pagination.length }}</b></span
                            >
                            <app-page-length-dropdown (emitLength)="changeLength($event)">
                            </app-page-length-dropdown>
                        </td>
                        <td>
                            <app-page-navigation
                                pageNumber="{{ pagination.getPageNo() }}"
                                totalPages="{{ pagination.getTotalPages() }}"
                                (changePage)="changePage($event)">
                            </app-page-navigation>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="waves-system-balance-box width-50" *ngIf="spinnerReconciliationData">
            <mat-progress-spinner
                class="example-margin"
                color="warn"
                mode="indeterminate"
                diameter="30">
            </mat-progress-spinner>
        </div>
    </section>
</div>
<div class="control-sidebar-bg"></div>
