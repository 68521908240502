import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericResponse } from '../model/generic.response';
import { AppSettings } from '../settings/app-settings';

@Injectable({ providedIn: 'root' })
export class DashboardService {
    constructor(private http: HttpClient) { }

    getDashboardBalance() {
        return this.http.get<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.GET_DASHBOARD_BALANCE,
        );
    }

    getWalletSummary(userId: string) {
        const path = AppSettings.GET_USER_WALLET_SUMMARY.replace('@1@', userId);
        return this.http.get<any>(AppSettings.API_ENDPOINT + path);
    }

    getReconciliationData(body: any) {
        return this.http.post<GenericResponse<any[]>>(
            AppSettings.API_ENDPOINT + AppSettings.GET_USER_RECONCILIATION_DATA,
            JSON.stringify(body),
        );
    }

    getSalesData(queryParams: any) {
        const params = new HttpParams({ fromObject: queryParams });
        return this.http.get<GenericResponse<any[]>>(
            AppSettings.API_ENDPOINT + AppSettings.GET_SALES_DATA,
            { params },
        );
    }
}
